import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"; // Import zodResolver
import { z } from "zod";
import { useState } from "react";
import { toast } from "sonner";
import { axiosInstance } from "../../axios/axiosInstance";

const schema = z.object({
  email: z.string().email("Invalid email address"),
});

const NewsletterSection = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const handleSubscribe = async (data) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/newsletter", {
        email_id: data.email,
      });
      if (response.status === 201) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="relative py-12 md:py-24">
        <img
          className="absolute top-0 left-1/2 transform -translate-x-1/2 -ml-10"
          src="edu-engineering-assets/newsletter/newsletter-light-top.png"
          alt=""
        />
        <div className="relative container px-4 mx-auto">
          <div className="max-w-6xl mx-auto">
            <div className="py-10 px-4 xs:px-10 text-center bg-white rounded-xl">
              <div className="max-w-md mx-auto">
                <h3 className="font-heading tracking-tight text-4xl font-bold mb-4">
                  Join Newsletter
                </h3>
                <p className="text-lg text-gray-500 mb-10">
                  Never Miss an Update from Us
                </p>
                <form onSubmit={handleSubmit(handleSubscribe)}>
                  <div className="xs:flex mb-5 items-center">
                    <input
                      className={`py-3 px-4 h-12 w-full mb-3 xs:mb-0 xs:mr-3 text-gray-500 placeholder-gray-500 border border-gray-200 focus:border-yellowGreen-500 outline-none ring ring-transparent focus:ring-yellowGreen-500 rounded-lg`}
                      type="email"
                      placeholder="Your email"
                      name="email"
                      {...register("email")}
                    />
                    <button
                      className="xs:flex-shrink-0 group relative flex xs:inline-flex w-full xs:w-auto items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300 focus:outline-none"
                      type="submit"
                      disabled={loading}>
                      <span>Subscribe</span>
                    </button>
                  </div>
                  {errors.email && (
                    <span className="flex justify-start w-[100%] mb-4 text-red-500 text-sm">
                      {errors.email.message}
                    </span>
                  )}
                  <span className="block text-gray-400 text-sm">
                    No spam. You can unsubscribe anytime.
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsletterSection;
