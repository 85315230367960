const FaqSection = () => {
  return (
    <>
      <section className="relative pt-12 pb-12 lg:pb-0 md:pt-24">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-1/2 px-4 mb-20 lg:mb-0 lg:pb-64">
              <div className="max-w-sm">
                <span className="inline-flex items-center h-6 mb-6 px-2 text-sm font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full">
                  FAQ
                </span>
                <h1 className="font-heading tracking-tight text-4xl sm:text-5xl font-semibold mb-10">
                  Frequently asked questions
                </h1>
                <a
                  className="group relative block xs:inline-block p-px text-sm font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                  href="#">
                  <div className="absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300" />
                  <div className="relative flex items-center justify-center px-4 h-10 bg-gradient-to-br from-cyanGreen-800 to-cyan-800 group-hover:text-green-200 rounded-lg transition duration-300">
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.3332 7.33333C13.3332 4.38781 10.9454 2 7.99984 2C5.05432 2 2.6665 4.38781 2.6665 7.33333"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.3335 9.77178V9.56155C1.3335 8.64381 1.95809 7.84385 2.84843 7.62126L3.25464 7.51971C3.63333 7.42504 4.00016 7.71145 4.00016 8.10179V11.2315C4.00016 11.6219 3.63333 11.9083 3.25464 11.8136L2.84842 11.7121C1.95809 11.4895 1.3335 10.6895 1.3335 9.77178Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M14.6667 9.77178V9.56155C14.6667 8.64381 14.0421 7.84385 13.1517 7.62126L12.7455 7.51971C12.3668 7.42504 12 7.71145 12 8.10179V11.2315C12 11.6219 12.3668 11.9083 12.7455 11.8136L13.1517 11.7121C14.0421 11.4895 14.6667 10.6895 14.6667 9.77178Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M13.3332 12V12C13.3332 12.9205 12.587 13.6667 11.6665 13.6667H9.6665"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M9 14.6667H7C6.44772 14.6667 6 14.219 6 13.6667C6 13.1144 6.44772 12.6667 7 12.6667H9C9.55228 12.6667 10 13.1144 10 13.6667C10 14.219 9.55228 14.6667 9 14.6667Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                    </svg>
                    <span className="ml-2">Talk to us</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-4">
              <div>
                <button className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none">
                  <div className="pr-4">
                    <h6 className="font-semibold">What is Edu Engineering</h6>
                    <p className="hidden group-hover:block mt-2 text-sm text-gray-500">
                      Edu Engineering is a comprehensive educational platform
                      dedicated to providing engineering students and
                      professionals with resources, tools, and opportunities to
                      enhance their knowledge and career prospects.
                    </p>
                  </div>
                  <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full">
                    <svg
                      className="group-hover:hidden"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      className="hidden group-hover:block"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 10L8 6L12 10"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
                <button className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none">
                  <div className="pr-4">
                    <h6 className="font-semibold">
                      How do I get started with Edu Engineering
                    </h6>
                    <p className="hidden group-hover:block mt-2 text-sm text-gray-500">
                      Getting started is simple! Just sign up for a free
                      account, explore our resource categories, and select the
                      tools or courses that meet your needs.
                    </p>
                  </div>
                  <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full">
                    <svg
                      className="group-hover:hidden"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      className="hidden group-hover:block"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 10L8 6L12 10"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
                <button className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none">
                  <div className="pr-4">
                    <h6 className="font-semibold">
                      Is there a cost to use Edu Engineering
                    </h6>
                    <p className="hidden group-hover:block mt-2 text-sm text-gray-500">
                      Absolutely, all the core resources and tools on [Platform
                      Name] are free for a lifetime. However, workshops and
                      specialized courses are exclusively available to our
                      premium users.
                    </p>
                  </div>
                  <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full">
                    <svg
                      className="group-hover:hidden"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      className="hidden group-hover:block"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 10L8 6L12 10"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
                <button className="group flex mb-3 items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none">
                  <div className="pr-4">
                    <h6 className="font-semibold">
                      Is engineering content available only for Anna University
                    </h6>
                    <p className="hidden group-hover:block mt-2 text-sm text-gray-500">
                      No, our platform currently supports students from 512
                      colleges affiliated with Anna University by providing
                      specialized educational resources. In the future, we plan
                      to expand our services to include other colleges. However,
                      our coding tools, workshops, and courses are universally
                      accessible and can be utilized by any engineering student
                      or working professional, regardless of their academic
                      affiliation.
                    </p>
                  </div>
                  <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full">
                    <svg
                      className="group-hover:hidden"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      className="hidden group-hover:block"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 10L8 6L12 10"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
                <button className="group flex items-center justify-between text-left w-full px-6 py-5 rounded-xl shadow-sm border border-gray-100 hover:border-yellowGreen-500 ring ring-transparent hover:ring-yellowGreen-500 focus:outline-none">
                  <div className="pr-4">
                    <h6 className="font-semibold">
                      Is Edu Resource updated every semester for all new
                      regulations of Anna University
                    </h6>
                    <p className="hidden group-hover:block mt-2 text-sm text-gray-500">
                      Yes, we update our resources every semester and
                      continuously add new materials to accommodate all current
                      and upcoming regulations of Anna University.
                    </p>
                  </div>
                  <div className="flex-shrink-0 flex w-7 h-7 items-center justify-center bg-gray-100 rounded-full">
                    <svg
                      className="group-hover:hidden"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      className="hidden group-hover:block"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4 10L8 6L12 10"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqSection;
