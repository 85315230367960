const FeaturesSection = () => {
  return (
    <>
      <section className="py-12 md:py-24">
        <div className="container px-4 mx-auto">
          <div>
            <div className="max-w-2xl mx-auto mb-24 text-center">
              <h1 className="font-heading tracking-tight text-4xl sm:text-5xl font-bold mb-4">
                Engineering Made Simple
              </h1>
              <p className="text-gray-500">
                Streamline your engineering education journey.{" "}
              </p>
              <p className="text-gray-500">
                From resources to internships, gain everything you need to
                succeed.
              </p>
            </div>
            <div className="flex flex-wrap -mx-4 -mb-16">
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-16">
                <div className="max-w-xs lg:px-4 mx-auto text-center border border-gray-100 rounded-xl shadow-4xl py-5">
                  <div className="flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-gray-50">
                    <svg
                      width={33}
                      height={32}
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.33301 23.4V8.6C4.33301 8.26863 4.60164 8 4.93301 8H22.6786C22.8792 8 23.0665 8.10026 23.1778 8.26718L28.1111 15.6672C28.2455 15.8687 28.2455 16.1313 28.1111 16.3328L23.1778 23.7328C23.0665 23.8997 22.8792 24 22.6786 24H4.93301C4.60164 24 4.33301 23.7314 4.33301 23.4Z"
                        stroke="#041109"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                  <div className="mt-3">
                    <h5 className="text-xl font-bold mb-1">
                      Best Engineering Resources
                    </h5>
                    <p className="text-lg text-gray-500">
                      Access top-tier educational resources, specifically
                      curated to enhance your engineering studies.
                    </p>
                    <div className="py-3">
                      <a
                        className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                        href="#">
                        <div className="absolute top-0 left-0 w-full h-full rounded-lg" />
                        <span className="mr-3">Access Resources</span>
                        <span className="transform group-hover:translate-x-1 transition duration-100">
                          <svg
                            width={7}
                            height={12}
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L6 6L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-6">
                <div className="max-w-xs lg:px-4 mx-auto text-center border border-gray-100 rounded-xl shadow-4xl py-5">
                  <div className="flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-gray-50">
                    <svg
                      width={33}
                      height={32}
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3 14.6667V5.93334C3 5.60197 3.26863 5.33334 3.6 5.33334H12.1114C12.2546 5.33334 12.3931 5.38458 12.5019 5.47779L16.8315 9.1889C16.9402 9.28211 17.0787 9.33334 17.222 9.33334H29.0667C29.398 9.33334 29.6667 9.60197 29.6667 9.93334V14.6667M3 14.6667V26.0667C3 26.398 3.26863 26.6667 3.6 26.6667H29.0667C29.398 26.6667 29.6667 26.398 29.6667 26.0667V14.6667M3 14.6667H29.6667"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mt-3">
                    <h5 className="text-xl font-bold mb-1">
                      Internship Opportunities
                    </h5>
                    <p className="text-lg text-gray-500">
                      {" "}
                      Gain invaluable industry experience and professional
                      skills through our targeted internship programs.
                    </p>
                    <div className="py-3">
                      <a
                        className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                        href="#">
                        <div className="absolute top-0 left-0 w-full h-full rounded-lg" />
                        <span className="mr-3">Apply for Internships</span>
                        <span className="transform group-hover:translate-x-1 transition duration-100">
                          <svg
                            width={7}
                            height={12}
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L6 6L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-16">
                <div className="max-w-xs lg:px-4 mx-auto text-center border border-gray-100 rounded-xl shadow-4xl py-5">
                  <div className="flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-gray-50">
                    <svg
                      width={33}
                      height={32}
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19 15.9968C19 12.6745 15.9107 9.33334 12.1429 9.33334C11.6965 9.33334 10.2254 9.33335 9.85714 9.33335C6.07005 9.33335 3 12.3167 3 15.9968C3 19.1681 5.27964 21.8219 8.33333 22.4952C8.82341 22.6033 9.33342 22.6603 9.85714 22.6603"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.666 15.9968C13.666 19.3191 16.7553 22.6603 20.5232 22.6603C20.9695 22.6603 22.4406 22.6603 22.8089 22.6603C26.596 22.6603 29.666 19.677 29.666 15.9968C29.666 12.8256 27.3864 10.1718 24.3327 9.49843C23.8426 9.39037 23.3326 9.33331 22.8089 9.33331"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mt-3">
                    <h5 className="text-xl font-bold mb-1">
                      {" "}
                      Interactive Workshops
                    </h5>
                    <p className="text-lg text-gray-500">
                      Develop hands-on engineering skills in our interactive
                      workshops designed to engage and educate.
                    </p>
                    <div className="py-3">
                      <a
                        className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                        href="#">
                        <div className="absolute top-0 left-0 w-full h-full rounded-lg" />
                        <span className="mr-3">Join Workshops</span>
                        <span className="transform group-hover:translate-x-1 transition duration-100">
                          <svg
                            width={7}
                            height={12}
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L6 6L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-16">
                <div className="max-w-xs lg:px-4 mx-auto text-center border border-gray-100 rounded-xl shadow-4xl py-5">
                  <div className="flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-gray-50">
                    <svg
                      width={33}
                      height={32}
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3018_21098)">
                        <path
                          d="M1.66602 26.6667V23C1.66602 19.134 4.80002 16 8.66601 16H13.3327C17.1987 16 20.3327 19.134 20.3327 23V26.6667"
                          stroke="#041109"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M17.666 18.6667V18.6667C17.666 14.9848 20.6508 12 24.3327 12V12C28.0146 12 30.9993 14.9848 30.9993 18.6667V19.3333"
                          stroke="#041109"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M10.9993 16C13.9449 16 16.3327 13.6122 16.3327 10.6667C16.3327 7.72115 13.9449 5.33334 10.9993 5.33334C8.05383 5.33334 5.66602 7.72115 5.66602 10.6667C5.66602 13.6122 8.05383 16 10.9993 16Z"
                          stroke="#041109"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M24.333 12C26.5421 12 28.333 10.2091 28.333 8C28.333 5.79086 26.5421 4 24.333 4C22.1239 4 20.333 5.79086 20.333 8C20.333 10.2091 22.1239 12 24.333 12Z"
                          stroke="#041109"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="mt-3">
                    <h5 className="text-xl font-bold mb-1">
                      Placement Roadmap
                    </h5>
                    <p className="text-lg text-gray-500">
                      Utilize our detailed placement roadmap to strategically
                      navigate your career path and achieve success.
                    </p>
                    <div className="py-3">
                      <a
                        className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                        href="#">
                        <div className="absolute top-0 left-0 w-full h-full rounded-lg" />
                        <span className="mr-3">View Roadmap</span>
                        <span className="transform group-hover:translate-x-1 transition duration-100">
                          <svg
                            width={7}
                            height={12}
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L6 6L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-16">
                <div className="max-w-xs lg:px-4 mx-auto text-center border border-gray-100 rounded-xl shadow-4xl py-5">
                  <div className="flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-gray-50">
                    <svg
                      width={33}
                      height={32}
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M28.333 4.6V27.4C28.333 27.7314 28.0644 28 27.733 28H4.93301C4.60164 28 4.33301 27.7314 4.33301 27.4V4.6C4.33301 4.26863 4.60164 4 4.93301 4H27.733C28.0644 4 28.333 4.26863 28.333 4.6Z"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.2307 11.1796C12.8307 10.9511 12.333 11.2399 12.333 11.7006V20.2994C12.333 20.7601 12.8307 21.0489 13.2307 20.8204L20.7547 16.5209C21.1578 16.2906 21.1578 15.7094 20.7547 15.4791L13.2307 11.1796Z"
                        stroke="#041109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="mt-3">
                    <h5 className="text-xl font-bold mb-1">Resume Building</h5>
                    <p className="text-lg text-gray-500">
                      Craft a standout resume with expert guidance to highlight
                      your skills and impress potential employers.
                    </p>
                    <div className="py-3">
                      <a
                        className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                        href="#">
                        <div className="absolute top-0 left-0 w-full h-full rounded-lg" />
                        <span className="mr-3">Build Your Resume</span>
                        <span className="transform group-hover:translate-x-1 transition duration-100">
                          <svg
                            width={7}
                            height={12}
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L6 6L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-16">
                <div className="max-w-xs lg:px-4 mx-auto text-center border border-gray-100 rounded-xl shadow-4xl py-5">
                  <div className="flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-gray-50">
                    <svg
                      width={33}
                      height={32}
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3018_21112)">
                        <path
                          d="M11 24L15 28L21.6667 21.3333"
                          stroke="#041109"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M26.9993 23.4763C28.991 22.6962 30.9993 20.9185 30.9993 17.3333C30.9993 12 26.5549 10.6667 24.3327 10.6667C24.3327 8 24.3327 2.66666 16.3327 2.66666C8.33268 2.66666 8.33268 8 8.33268 10.6667C6.11046 10.6667 1.66602 12 1.66602 17.3333C1.66602 20.9185 3.67438 22.6962 5.66602 23.4763"
                          stroke="#041109"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="mt-3">
                    <h5 className="text-xl font-bold mb-1">
                      Coding Mastery Tools
                    </h5>
                    <p className="text-lg text-gray-500">
                      Master coding quickly with our integrated platform and
                      essential cheat sheets, designed for efficiency and
                      effectiveness.
                    </p>
                    <div className="py-3">
                      <a
                        className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                        href="#">
                        <div className="absolute top-0 left-0 w-full h-full rounded-lg" />
                        <span className="mr-3">Start Coding</span>
                        <span className="transform group-hover:translate-x-1 transition duration-100">
                          <svg
                            width={7}
                            height={12}
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L6 6L1 11"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesSection;
