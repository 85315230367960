const Header = () => {
  return (
    <>
      <>
        <section className="relative">
          <img
            className="absolute top-0 left-0 w-full h-full"
            src="edu-engineering-assets/headers/green-blur-bg-circle.png"
            alt=""
          />
          <nav className="relative bg-white">
            <div className="container mx-auto px-4">
              <div className="relative flex h-24 items-center">
                <a className="inline-block" href="#">
                  <img
                    className="h-10"
                    src="https://static.Edu Engineering.dev/uploads/files/b9/b955c8bf43268f80bff9108cca12c5f7c8fa2f77/EduEngineering-Logo.webp"
                    alt=""
                  />
                </a>
                <button className="lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3 5H21"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 12H21"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 19H21"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center">
                  <a
                    className="inline-block hover:text-yellowGreen-700 mr-10"
                    href="#"></a>
                  <a
                    className="inline-block hover:text-yellowGreen-700 mr-10"
                    href="#">
                    Features
                  </a>
                  <a
                    className="inline-block hover:text-yellowGreen-700 mr-10"
                    href="#">
                    CGPA
                  </a>
                  <a
                    className="inline-block hover:text-yellowGreen-700"
                    href="#">
                    Resources
                  </a>
                </div>
                <div className="hidden lg:block ml-auto">
                  {/* <a
                    className="inline-flex items-center justify-center h-10 mr-4 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200"
                    href="#">
                    Login
                  </a> */}
                  <a
                    className="inline-flex items-center justify-center h-10 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200"
                    href="#">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </nav>
         
          <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50">
            <div className="navbar-backdrop fixed inset-0 bg-teal-800 opacity-70" />
            <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto">
              <div className="flex mb-auto items-center">
                <a className="inline-block mr-auto" href="#">
                  <img
                    className="h-10"
                    src="edu-engineering-assets/logos/aurora-logo.svg"
                    alt=""
                  />
                </a>
                <a className="navbar-close" href="#">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <div className="py-12 mb-auto">
                <ul className="flex-col">
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      Product
                    </a>
                  </li>
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      Features
                    </a>
                  </li>
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className="inline-block text-black" href="#">
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className="flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200"
                  href="#">
                  Login
                </a>
                <a
                  className="flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200"
                  href="#">
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
      </>
    </>
  );
};

export default Header;
