import { Suspense, lazy } from "react";
import Error from "../components/Error/Error";

import AppLayout from "../layout/AppLayout";
import HomePage from "../pages/HomePage";

const LazyPrivacyPolicy = lazy(() =>
  import("../components/PrivacyPolicy/PrivacyPolicy")
);
const LazyRefundPolicy = lazy(() =>
  import("../components/RefundPolicy/RefundPolicy")
);
const LazyTermAndCondition = lazy(() =>
  import("../components/TermsAndConditions/TermsAndConditions")
);

const routes = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/privacy-policy",
        element: (
          <Suspense fallback={<p>Loading...</p>}>
            <LazyPrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "/refund-policy",
        element: (
          <Suspense fallback={<p>Loading...</p>}>
            <LazyRefundPolicy />
          </Suspense>
        ),
      },
      {
        path: "/terms-and-conditions",
        element: (
          <Suspense fallback={<p>Loading...</p>}>
            <LazyTermAndCondition />
          </Suspense>
        ),
      },
      {
        path: "/careers",
        element: (
          <Suspense fallback={<p>Loading...</p>}>
            <LazyTermAndCondition />
          </Suspense>
        ),
      },
    ],
    errorElement: <Error />,
  },
  {
    path: "/sitemap.xml",
    element: <Error />,
  },
];

export default [...routes];
