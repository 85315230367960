const PartnerLogoSection = () => {
  return (
    <>
      <section className="py-12 md:py-24">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10">
              <div className="flex items-center justify-center lg:w-40 h-full px-5 py-3 border border-gray-200 rounded-xl shadow-sm">
                <img
                  src="edu-engineering-assets/logo-clouds/logo-ipsum-7.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10">
              <div className="flex items-center justify-center lg:w-40 h-full px-5 py-3 border border-gray-200 rounded-xl shadow-sm">
                <img
                  src="edu-engineering-assets/logo-clouds/logo-ipsum-6.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10">
              <div className="flex items-center justify-center lg:w-40 h-full px-5 py-3 border border-gray-200 rounded-xl shadow-sm">
                <img
                  src="edu-engineering-assets/logo-clouds/logo-ipsum-8.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10">
              <div className="flex items-center justify-center lg:w-40 h-full px-5 py-3 border border-gray-200 rounded-xl shadow-sm">
                <img
                  src="edu-engineering-assets/logo-clouds/logo-ipsum-5.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10">
              <div className="flex items-center justify-center lg:w-40 h-full px-5 py-3 border border-gray-200 rounded-xl shadow-sm">
                <img
                  src="edu-engineering-assets/logo-clouds/logo-ipsum-4.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 lg:w-1/6 px-4 mb-10">
              <div className="flex items-center justify-center lg:w-40 h-full px-5 py-3 border border-gray-200 rounded-xl shadow-sm">
                <img
                  src="edu-engineering-assets/logo-clouds/logo-ipsum-3.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnerLogoSection;
