import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Toaster } from "sonner";
import routes from "./routes";

const container = document.getElementById("root");
const root = createRoot(container);

let browerRouter = createBrowserRouter(routes);

root.render(
  <React.StrictMode>
    <RouterProvider router={browerRouter} />
  </React.StrictMode>
);
