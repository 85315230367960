import { Outlet } from "react-router-dom";
import { Toaster } from "sonner";
import { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import ReactGA from "react-ga";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
ReactGA.initialize("");

const AppLayout = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
      {/* The rest of your application */}
      <Toaster position="top-center" richColors />
    </>
  );
};

export default AppLayout;
