function TestimonialsSection() {
  return (
    <>
      <section className="py-12 md:py-24">
        <div className="container px-4 mx-auto">
          <div className="max-w-7xl mx-auto">
            <div className="max-w-2xl mx-auto mb-20 text-center">
              <span className="inline-flex items-center h-6 mb-6 px-2 text-xs uppercase font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full">
                TESTIMONIALS
              </span>
              <h1 className="font-heading tracking-tight text-4xl sm:text-5xl font-bold">
                Success Stories from Our Community
              </h1>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/3 px-4 mb-10 lg:mb-0">
                <div className="flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 border border-gray-100 rounded-xl shadow-4xl">
                  <div className="pr-4">
                    <p className="text-lg font-medium mb-20">
                      “We got our first lead through bluelearn! Right after my
                      pitch in the bluelearn pitch tank competition, I got my
                      first lead. Thanks a lot for this crazy community you guys
                      have built.”
                    </p>
                    <div className="flex items-center mt-auto">
                      <img
                        className="block h-12 w-12 rounded-full"
                        src="edu-engineering-assets/blog/avatar-medium.png"
                        alt=""
                      />
                      <div className="pl-4">
                        <span className="block font-semibold">
                          Mehul Kapadia
                        </span>
                        <span className="text-sm text-gray-500"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-4 mb-10 lg:mb-0">
                <div className="flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 border border-gray-100 rounded-xl shadow-4xl">
                  <div className="pr-4">
                    <p className="text-lg font-medium mb-20">
                      “We got our first lead through bluelearn! Right after my
                      pitch in the bluelearn pitch tank competition, I got my
                      first lead. Thanks a lot for this crazy community you guys
                      have built.”
                    </p>
                    <div className="flex items-center mt-auto">
                      <img
                        className="block h-12 w-12 rounded-full"
                        src="edu-engineering-assets/blog/avatar-medium.png"
                        alt=""
                      />
                      <div className="pl-4">
                        <span className="block font-semibold">
                          Mehul Kapadia
                        </span>
                        <span className="text-sm text-gray-500"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-4">
                <div className="flex flex-col max-w-sm mx-auto lg:max-w-none items-start h-full p-8 border border-gray-100 rounded-xl shadow-4xl">
                  <div className="pr-4">
                    <p className="text-lg font-medium mb-20">
                      “We got our first lead through bluelearn! Right after my
                      pitch in the bluelearn pitch tank competition, I got my
                      first lead. Thanks a lot for this crazy community you guys
                      have built.”
                    </p>
                    <div className="flex items-center mt-auto">
                      <img
                        className="block h-12 w-12 rounded-full"
                        src="edu-engineering-assets/blog/avatar-medium.png"
                        alt=""
                      />
                      <div className="pl-4">
                        <span className="block font-semibold">
                          Mehul Kapadia
                        </span>
                        <span className="text-sm text-gray-500"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TestimonialsSection;