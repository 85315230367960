import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
import FeaturesSection from "../components/FeaturesSection/FeaturesSection";
import HowItWorksSection from "../components/HowItWorksSection/HowItWorksSection";
import TestimonialsSection from "../components/TestimonialsSection/TestimonialsSection";
import FaqSection from "../components/FaqSection/FaqSection";
import StatsSection from "../components/StatsSection/StatsSection";
import NewsletterSection from "../components/NewsletterSection/NewsletterSection";
import PartnerLogoSection from "../components/PartnerLogoSection/PartnerLogoSection";
import ContactSection from "../components/ContactSection/ContactSection";
import Footer from "../components/Footer/Footer";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function HomePage() {
  return (
    <>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <HeroSection />
      <FeaturesSection />
      <HowItWorksSection />
      <TestimonialsSection />
      <FaqSection />
      <StatsSection />
      <NewsletterSection />
      <PartnerLogoSection />
      <ContactSection />
    </>
  );
}
