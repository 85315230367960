const HeroSection = () => {
  return (
    <>
      <>
        <section className="relative">
          <img
            className="absolute top-0 left-0 w-full h-full"
            src="edu-engineering-assets/headers/green-blur-bg-circle.png"
            alt=""
          />
          {/* <nav className="relative bg-white">
            <div className="container mx-auto px-4">
              <div className="relative flex h-24 items-center">
                <a className="inline-block" href="#">
                  <img
                    className="h-10"
                    src="https://static.Edu Engineering.dev/uploads/files/b9/b955c8bf43268f80bff9108cca12c5f7c8fa2f77/EduEngineering-Logo.webp"
                    alt=""
                  />
                </a>
                <button className="lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3 5H21"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 12H21"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 19H21"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center">
                  <a
                    className="inline-block hover:text-yellowGreen-700 mr-10"
                    href="#"></a>
                  <a
                    className="inline-block hover:text-yellowGreen-700 mr-10"
                    href="#">
                    Features
                  </a>
                  <a
                    className="inline-block hover:text-yellowGreen-700 mr-10"
                    href="#">
                    CGPA
                  </a>
                  <a
                    className="inline-block hover:text-yellowGreen-700"
                    href="#">
                    Resources
                  </a>
                </div>
                <div className="hidden lg:block ml-auto">
                  <a
                    className="inline-flex items-center justify-center h-10 mr-4 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold transition duration-200"
                    href="#">
                    Login
                  </a>
                  <a
                    className="inline-flex items-center justify-center h-10 px-4 text-center leading-loose text-sm text-gray-700 hover:text-yellowGreen-700 font-semibold border border-gray-200 hover:border-yellowGreen-600 shadow-sm hover:shadow-none rounded-lg transition duration-200"
                    href="#">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </nav> */}
          <div className="relative pt-12 sm:pt-24">
            <div className="container px-4 mx-auto">
              <div className="max-w-2xl xl:px-5 mb-14 mx-auto text-center">
                <a
                  className="group relative inline-flex mb-6 items-center rounded-full shadow-md overflow-hidden"
                  href="#">
                  <div className="animate-showLine absolute bottom-0 left-1/2 transform -translate-x-1/2 p-px mx-auto w-auto bg-gradient-to-r from-transparent via-yellowGreen-500 to-transparent group-hover:via-teal-600" />
                  <div className="flex flex-wrap sm:flex-nowrap items-center px-6 sm:px-4 py-4 sm:py-2 bg-white group-hover:bg-cyanGreen-50 transition duration-300 rounded-full">
                    <span className="text-sm">
                      Connect with 25,000+ engineers and elevate your skills
                      with our community!
                    </span>
                    <div className="mt-1 sm:mt-0 mx-auto sm:mr-0 sm:ml-2 flex items-center">
                      <span className="mr-3 inline-block text-transparent bg-clip-text bg-gradient-to-b font-bold from-green-500 via-green-500 to-teal-400 group-hover:from-cyan-400 group-hover:via-cyan-700 group-hover:to-cyan-600">
                        Join Now
                      </span>
                      <span className="group-hover:hidden">
                        <svg
                          width={11}
                          height={10}
                          viewBox="0 0 11 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9"
                            stroke="url(#paint0_linear_3112_14890)"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_3112_14890"
                              x1="9.46358"
                              y1="-1.5"
                              x2="5.46163"
                              y2="9.11123"
                              gradientUnits="userSpaceOnUse">
                              <stop stopColor="#ACE348">
                                <stop offset={1} stopColor="#25ACA0" />
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className="hidden group-hover:block">
                        <svg
                          width={11}
                          height={10}
                          viewBox="0 0 11 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1.00004 5H9.33337M9.33337 5L5.33337 1M9.33337 5L5.33337 9"
                            stroke="#25ACA0"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </a>
                <div className="max-w-lg sm:max-w-xl lg:max-w-none mx-auto">
                  <h1 className="font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
                    All your engineering needs, at one place.
                  </h1>
                  <p className="max-w-lg mx-auto text-lg text-gray-700 mb-10">
                    Facilitates effortless access to educational tools,
                    empowering you to focus on learning, not searching.
                  </p>
                  <a
                    className="group relative flex xs:inline-flex items-center justify-center px-5 h-12 font-bold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                    href="#">
                    <div className="absolute top-0 left-0 w-full h-full rounded-lg ring-4 ring-green-300 animate-pulse group-hover:ring-0 transition duration-300" />
                    <span className="mr-3">Start Learning</span>
                    <span className="transform group-hover:translate-x-1 transition duration-100">
                      <svg
                        width={7}
                        height={12}
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1 1L6 6L1 11"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
              <img
                className="block mx-auto"
                src="edu-engineering-assets/headers/header-center.png"
                alt=""
              />
            </div>
          </div>
          {/* <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50">
            <div className="navbar-backdrop fixed inset-0 bg-teal-800 opacity-70" />
            <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto">
              <div className="flex mb-auto items-center">
                <a className="inline-block mr-auto" href="#">
                  <img
                    className="h-10"
                    src="edu-engineering-assets/logos/aurora-logo.svg"
                    alt=""
                  />
                </a>
                <a className="navbar-close" href="#">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <div className="py-12 mb-auto">
                <ul className="flex-col">
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      Product
                    </a>
                  </li>
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      Features
                    </a>
                  </li>
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className="inline-block text-black" href="#">
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className="flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200"
                  href="#">
                  Login
                </a>
                <a
                  className="flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200"
                  href="#">
                  Get Started
                </a>
              </div>
            </nav>
          </div> */}
        </section>
      </>
    </>
  );
}

export default HeroSection