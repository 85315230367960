import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "sonner";
import { axiosInstance } from "../../axios/axiosInstance";

const schema = z.object({
  first_name: z.string().min(3, "Enter at least 3 characters"),
  last_name: z.string().min(1, "Last name is required"),
  email_id: z.string().email("Enter a valid email address"),
  phone_number: z
    .string()
    .min(10, "Phone number must be 10 digits")
    .max(10, "Phone number must be 10 digits"),
  message: z.string().min(10, "Enter at least 10 characters"),
});

const ContactSection = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await axiosInstance.post("/contact-us/submit", data);
      toast.success("Message sent successfully!");
      reset();
    } catch (error) {
      toast.error("Message sending failed. Please try again later.");
    }
  };

  return (
    <>
      <section className="py-12 md:py-24">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-5/12 xl:w-1/2 px-4 mb-16 lg:mb-0">
              <div className="max-w-sm">
                <span className="inline-flex items-center h-7 mb-6 px-2 text-sm font-medium text-yellowGreen-700 bg-yellowGreen-200 rounded-full">
                  {/* Support Icon */}
                  <span className="ml-1">Contact Support</span>
                </span>
                <h1 className="font-heading tracking-tight text-4xl xs:text-5xl font-bold mb-4">
                  How can we help you today?
                </h1>
                <p className="text-lg text-gray-500 mb-10">
                  Submit your info and we’ll get back to you as soon as
                  possible.
                </p>
              </div>
            </div>
            <div className="w-full lg:w-7/12 xl:w-1/2 px-4">
              <div className="p-8 border border-gray-100 rounded-xl">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-wrap -mx-4 mb-8 items-center">
                    <div className="w-full md:w-1/2 px-4 mb-4">
                      <label className="block mb-1 text-sm font-medium">
                        First name
                      </label>
                      <input
                        className="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500"
                        type="text"
                        placeholder="First name"
                        name="first_name"
                        {...register("first_name")}
                      />
                      {errors.first_name && (
                        <p className="text-red-500">
                          {errors.first_name.message}
                        </p>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 px-4 mb-4">
                      <label className="block mb-1 text-sm font-medium">
                        Last name
                      </label>
                      <input
                        className="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500"
                        type="text"
                        placeholder="Last name"
                        name="last_name"
                        {...register("last_name")}
                      />
                      {errors.last_name && (
                        <p className="text-red-500">
                          {errors.last_name.message}
                        </p>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 px-4 mb-4">
                      <label className="block mb-1 text-sm font-medium">
                        Email
                      </label>
                      <input
                        className="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500"
                        type="email"
                        placeholder="john@email.com"
                        name="email_id"
                        {...register("email_id")}
                      />
                      {errors.email_id && (
                        <p className="text-red-500">
                          {errors.email_id.message}
                        </p>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 px-4 mb-4">
                      <label className="block mb-1 text-sm font-medium">
                        Phone number
                      </label>
                      <input
                        className="py-2 px-4 h-11 w-full text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500"
                        type="text"
                        placeholder="+1 (555) 000-0000"
                        name="phone_number"
                        {...register("phone_number")}
                      />
                      {errors.phone_number && (
                        <p className="text-red-500">
                          {errors.phone_number.message}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-4">
                      <label className="block mb-1 text-sm font-medium">
                        Message
                      </label>
                      <textarea
                        className="block py-2 px-4 w-full h-44 text-gray-500 placeholder-gray-500 bg-gray-50 bg-opacity-40 border border-gray-200 focus:border-yellowGreen-500 rounded-lg outline-none ring ring-transparent focus:ring-yellowGreen-500 resize-none"
                        placeholder="Enter your message"
                        name="message"
                        {...register("message")}
                      />
                      {errors.message && (
                        <p className="text-red-500">{errors.message.message}</p>
                      )}
                    </div>
                  </div>
                  <button
                    className="group relative flex items-center justify-center px-5 h-12 w-full mb-8 text-base font-semibold text-white bg-gradient-to-br from-cyanGreen-800 to-cyan-800 rounded-lg transition-all duration-300"
                    type="submit">
                    <div className="absolute top-0 left-0 w-full h-full rounded-lg ring ring-green-300 animate-pulse group-hover:ring-0 transition duration-300" />
                    <span>Submit</span>
                  </button>
                  <p className="text-sm text-gray-500">
                    <span className="mr-1">
                      We process your information in accordance with our
                    </span>
                    <a
                      className="inline-block font-semibold text-yellowGreen-700 hover:text-yellowGreen-600"
                      href="#">
                      Privacy Policy
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
