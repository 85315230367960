const StatsSection = () => {
  return (
    <>
      <section className="py-12 md:py-24">
        <div className="container px-4 mx-auto">
          <div className="text-center">
            <h3 className="font-heading tracking-tight text-4xl md:text-5xl font-bold mb-20">
              Let the numbers do the talking
            </h3>
            <div className="flex flex-wrap -mx-4 -mb-12">
              <div className="w-full xs:w-1/2 lg:w-1/4 px-4 mb-12">
                <div>
                  <span className="block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600">
                    1M+
                  </span>
                  <span className="text-lg text-gray-500">Happy Users</span>
                </div>
              </div>
              <div className="w-full xs:w-1/2 lg:w-1/4 px-4 mb-12">
                <div>
                  <span className="block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600">
                    30k+
                  </span>
                  <span className="text-lg text-gray-500">
                    Community Member
                  </span>
                </div>
              </div>
              <div className="w-full xs:w-1/2 lg:w-1/4 px-4 mb-12">
                <div>
                  <span className="block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600">
                    100+
                  </span>
                  <span className="text-lg text-gray-500">
                    College Across TN
                  </span>
                </div>
              </div>
              <div className="w-full xs:w-1/2 lg:w-1/4 px-4 mb-12">
                <div>
                  <span className="block mb-4 text-5xl text-transparent bg-clip-text bg-gradient-to-b font-bold from-cyanGreen-800 via-green-700 to-cyan-600">
                    500+
                  </span>
                  <span className="text-lg text-gray-500">
                    Resources Available
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StatsSection;